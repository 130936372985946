import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { alertsKeys } from './alerts';
import {
    details,
    list,
    DeviceIdPayload,
    DeviceListPayload,
    DeviceListResponse,
    DeviceUpdatePayload,
    update,
    DeviceAlertHistoryFilterStatsResponse,
    alertHistoryFilterStats,
    remove,
    generation,
    DeviceExportResponse,
    subscriptionFileGeneration,
    SubscriptionFileGenerationPayload,
} from './api/devices';
import { Device } from './api/types';

export const devicesKeys = {
    all: ['devices'],
    lists: () => [...devicesKeys.all, 'list'],
    list: (params?: DeviceListPayload) => [...devicesKeys.lists(), params],
    export: (params?: DeviceListPayload) => [...alertsKeys.all, 'export', params],
    details: () => [...devicesKeys.all, 'details'],
    detail: (id?: DeviceIdPayload) => [...devicesKeys.details(), id],
    alertHistoryFilterStats: (id?: DeviceIdPayload) => ['alertHistoryFilterStats', id],
    exportSubscriptionFiles: () => [devicesKeys.all, 'exports'],
    exportSubscriptionFile: (params?: SubscriptionFileGenerationPayload) => [
        ...devicesKeys.exportSubscriptionFiles(),
        params,
    ],
};

export const useDeviceList = <TData = DeviceListResponse>(
    params?: DeviceListPayload,
    options?: UseQueryOptions<DeviceListResponse, AxiosError, TData>
) => {
    return useQuery<DeviceListResponse, AxiosError, TData>(
        devicesKeys.list(params),
        async () => await list(params).then((response) => response.data),
        {
            keepPreviousData: true,
            ...options,
        }
    );
};

export const useDeviceExport = <TData = DeviceExportResponse>(
    params?: DeviceListPayload,
    options?: UseQueryOptions<DeviceExportResponse, AxiosError, TData>
) => {
    return useQuery<DeviceExportResponse, AxiosError, TData>(
        devicesKeys.export(params),
        async () => await list(params, { headers: { Accept: '\tapplication/vnd.ms-excel' } }),
        {
            ...options,
        }
    );
};

export const useSelectedDevicesExport = <TData = DeviceExportResponse>(
    params?: DeviceListPayload,
    options?: UseQueryOptions<DeviceExportResponse, AxiosError, TData>
) => {
    return useQuery<DeviceExportResponse, AxiosError, TData>(
        devicesKeys.export(params),
        async () => await list(params, { headers: { Accept: '\tapplication/vnd.ms-excel' }, responseType: 'blob' }),
        {
            ...options,
        }
    );
};
export const useSelectedDevicesExportPDF = <TData = DeviceExportResponse>(
    params?: DeviceListPayload,
    options?: UseQueryOptions<DeviceExportResponse, AxiosError, TData>
) => {
    return useQuery<DeviceExportResponse, AxiosError, TData>(
        devicesKeys.export(params),
        async () => await list(params, { headers: { Accept: '\tapplication/pdf' }, responseType: 'blob' }),
        {
            ...options,
        }
    );
};

export const useDeviceAlertHistoryFilterStats = <TData = DeviceAlertHistoryFilterStatsResponse>(
    params?: DeviceIdPayload,
    options?: UseQueryOptions<DeviceAlertHistoryFilterStatsResponse, AxiosError, TData>
) => {
    return useQuery<DeviceAlertHistoryFilterStatsResponse, AxiosError, TData>(
        devicesKeys.alertHistoryFilterStats(params),
        async () => await alertHistoryFilterStats(params),
        options
    );
};

export const useDeviceDetails = <TData = Device>(
    id?: DeviceIdPayload,
    options?: UseQueryOptions<Device, AxiosError, TData>
) => {
    return useQuery<Device, AxiosError, TData>(devicesKeys.detail(id), async () => await details(id), options);
};

export const useDeviceUpdate = (options?: UseMutationOptions<Device, AxiosError, DeviceUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Device, AxiosError, DeviceUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail
            queryClient.invalidateQueries(devicesKeys.detail(variables.id));

            // invalidate list
            queryClient.invalidateQueries(devicesKeys.lists());

            // invalidate alert detail queries
            queryClient.invalidateQueries(alertsKeys.details());
        },
    });
};

export const useDeviceRemove = (options?: UseMutationOptions<undefined, AxiosError, DeviceIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, DeviceIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(devicesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(devicesKeys.lists());
        },
    });
};

export const useDeviceGeneration = (options?: UseMutationOptions<undefined, AxiosError, string>) => {
    return useMutation<undefined, AxiosError, string>(async (params) => await generation(params), options);
};

export const useSubscriptionFileGeneration = (
    params?: SubscriptionFileGenerationPayload,
    options?: UseQueryOptions<Blob, AxiosError>
) => {
    return useQuery<Blob, AxiosError>(
        devicesKeys.exportSubscriptionFile(params),
        async () => await subscriptionFileGeneration(params),
        options
    );
};
