import { selectUnit } from '@formatjs/intl-utils';

import {
    Alert,
    AlertColumnKey,
    AlertPendingReason,
    AlertStatus,
    Color,
    MailResponseStatus,
    OrderStatus,
} from '../queries/api/types';

export const defaultErrorMessage =
    'Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard ou contacter un administrateur';

export const defaultErrorMessageWithStatus = (status?: string | number) => `${defaultErrorMessage} (${status ?? 0})`;

export const requiredFieldsText = '* Champs obligatoires';
export const requiredFieldText = '* Champ obligatoire';

export const formatNumber = (value?: number | null, options?: Intl.NumberFormatOptions) => {
    if (value === undefined || value === null) {
        return '-';
    }

    return Intl.NumberFormat('fr-FR', options).format(value);
};

export const formatNumberSmall = (value?: number | null, options?: Intl.NumberFormatOptions) => {
    if (value === undefined || value === null) {
        return '-';
    }

    if (value >= 1000000) {
        return `${Intl.NumberFormat('fr-FR', options).format(value / 1000000)}M`;
    } else if (value >= 1000) {
        return `${Intl.NumberFormat('fr-FR', options).format(value / 1000)}k`;
    }

    return Intl.NumberFormat('fr-FR', options).format(value);
};

export const formatDate = (value?: string | Date | null, options?: Intl.DateTimeFormatOptions) => {
    if (value === undefined || value === null) {
        return '-';
    }

    const date = value instanceof Date ? value : new Date(value);

    return Intl.DateTimeFormat(
        'fr-FR',
        options ?? {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        }
    ).format(date);
};

export const formatRelativeTime = (val?: string | Date | null, options?: Intl.RelativeTimeFormatOptions) => {
    if (val === undefined || val === null) {
        return '-';
    }

    const date = val instanceof Date ? val : new Date(val);
    const { value, unit } = selectUnit(Date.now() - (Date.now() - date.getTime()));

    return new Intl.RelativeTimeFormat(
        'fr-FR',
        options ?? {
            numeric: 'auto',
            style: 'short',
        }
    ).format(value, unit);
};

export const translateAlertStatus = (status?: AlertStatus) => {
    switch (status) {
        case AlertStatus.received:
        case AlertStatus.inProgress:
            return 'Reçu';
        case AlertStatus.pending:
            return 'Attente';
        case AlertStatus.ignored:
            return 'Ignoré';
        case AlertStatus.routed:
            return 'Routé';
        case AlertStatus.treated:
            return 'Traité';
        case AlertStatus.registered:
            return 'Enregistré';

        default:
            return status ?? '-';
    }
};

export const translateOrderStatus = (status?: OrderStatus) => {
    switch (status) {
        case OrderStatus.inProgress:
            return 'En cours';
        case OrderStatus.shipped:
            return 'Expédiée';
        case OrderStatus.deliveredInTotality:
            return 'Livré en totalité';
        case OrderStatus.partiallyDelivered:
            return 'Livré partiellement';

        default:
            return status ?? '-';
    }
};

export const translateMailResponseStatus = (status?: MailResponseStatus) => {
    switch (status) {
        case MailResponseStatus.toBeProcessed:
            return "En attente d'envoi";
        case MailResponseStatus.inProgress:
            return "En cours d'envoi";
        case MailResponseStatus.sended:
            return 'Envoyé';

        default:
            return status ?? '-';
    }
};

export const translateAlertListStatus = (alert?: Alert) => {
    if (!alert) {
        return '-';
    }

    return [alert.alertType.reference, translateAlertStatus(alert.status)].filter(Boolean).join(' ');
};

export const translateAlertPendingReason = (reason?: AlertPendingReason) => {
    switch (reason) {
        case AlertPendingReason.accountingAlert:
            return 'Alerte comptable';
        case AlertPendingReason.billableCustomer:
            return 'Client facturable';
        case AlertPendingReason.other:
            return 'Autre motif';

        default:
            return reason ?? '-';
    }
};

export const translateAlertColumn = (column?: AlertColumnKey) => {
    switch (column) {
        case AlertColumnKey.reference:
            return "N° d'alerte";
        case AlertColumnKey.status:
            return 'Statut';
        case AlertColumnKey.organizationReference:
            return 'Org.';
        case AlertColumnKey.date:
            return 'Date & heure';
        case AlertColumnKey.deviceSerial:
            return 'N° de série';
        case AlertColumnKey.object:
            return 'Objet';
        case AlertColumnKey.deviceHardCodedDeviceInfoModel:
            return 'Matériel';
        case AlertColumnKey.pendingStatus:
            return 'Préconisation';
        case AlertColumnKey.errorCodeReference:
            return 'Code panne';
        case AlertColumnKey.colorReferenceToOrder:
            return 'Référence';
        case AlertColumnKey.deviceHardCodedDeviceInfoCustomerName:
            return 'Nom du client';
        case AlertColumnKey.deviceHardCodedDeviceInfoCustomerMail:
            return 'Email du client';
        case AlertColumnKey.deviceHardCodedDeviceInfoCustomerPhone:
            return 'Téléphone';
        case AlertColumnKey.deviceHardCodedDeviceInfoCustomerAddressOneLineAddress:
            return 'Contact';
        case AlertColumnKey.operatorFirstName:
            return 'Opérateur';
        case AlertColumnKey.treatedAt:
            return 'Traitement';

        default:
            return column ?? '-';
    }
};

export const translateTonerColor = (color?: Color | 'colors' | 'lowColor') => {
    switch (color) {
        case Color.black:
            return 'Black';
        case Color.cyan:
            return 'Cyan';
        case Color.erasableBlue:
            return 'Effac.';
        case Color.magenta:
            return 'Magenta';
        case Color.yellow:
            return 'Yellow';
        case 'lowColor':
            return 'Marianne';
        case 'colors':
            return 'Couleur';

        default:
            return color ?? '-';
    }
};

export const translatePrintingType = (label: 'copy' | 'fax' | 'printing') => {
    switch (label) {
        case 'copy':
            return 'Copie';
        case 'printing':
            return 'Impr.';
        case 'fax':
            return 'Fax';

        default:
            return label;
    }
};
