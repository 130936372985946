import dayjs from 'dayjs';
import { Descriptions, Empty, Typography, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { VFC } from 'react';

import ColorUsagePieChart from '../../components/charts/ColorUsagePieChart';
import CopyPrintUsagePieChart from '../../components/charts/CopyPrintUsagePieChart';
import YearlyPrintVolumeEvolution from '../../components/charts/YearlyPrintVolumeEvolution';
import YearlyVolumeEvolution from '../../components/charts/YearlyVolumeEvolution';
import Logo from '../../components/Logo';
import NoData from '../../components/NoData';
import { formatDate, formatNumber } from '../../helpers/i18n';
import useQueryParams from '../../hooks/queryParams';
import { MonthlyCounter } from '../../queries/api/types';
import { useDeviceDetails } from '../../queries/devices';
import AverageConsumptionStatsFaxScan from './AverageConsumptionStatsFaxScan';
import AverageConsumptionStatsSummary from './AverageConsumptionStatsSummary';
import { isDeviceUsingColors, isDeviceUsingErasableBlue, isDeviceUsingLowColor } from '../../helpers';

const ExportDevice: VFC = () => {
    const [queryParams] = useQueryParams('AlertStats');
    const deviceId = queryParams.get('deviceId') ?? undefined;
    const { data: device } = useDeviceDetails(deviceId);
    const columns: ColumnsType<MonthlyCounter> = [
        {
            key: 'lastDateOfMonth',
            title: 'Période',
            render: (_, record) =>
                record?.lastDateOfMonth ? (
                    formatDate(record?.lastDateOfMonth, { month: 'numeric', year: 'numeric' })
                ) : (
                    <NoData />
                ),
            width: 40,
        },
        {
            key: 'vmm',
            title: 'Volume tot. mens.',
            render: (_, record) =>
                formatNumber(
                    (record?.black ?? 0) + (record?.color ?? 0) + (record?.lowColor ?? 0) + (record?.erasableBlue ?? 0)
                ),
            width: 130,
        },
        {
            key: 'black',
            title: 'Noir',
            render: (_, record) => formatNumber(record?.black),
            width: 79,
        },
        {
            key: 'color',
            title: 'Couleur',
            render: (_, record) => (isDeviceUsingColors(device) ? formatNumber(record?.color) : '-'),
            width: 103,
        },
        {
            key: 'lowColor',
            title: 'Marianne',
            render: (_, record) => (isDeviceUsingLowColor(device) ? formatNumber(record?.lowColor) : '-'),
            width: 114,
        },
        {
            key: 'erasableBlue',
            title: 'Bleu eff.',
            render: (_, record) => (isDeviceUsingErasableBlue(device) ? formatNumber(record?.erasableBlue) : '-'),
            width: 142,
        },
        {
            key: 'copy',
            title: 'Copie',
            render: (_, record) => formatNumber(record?.copy),
            width: 114,
        },
        {
            key: 'printing',
            title: 'Impr.',
            render: (_, record) => formatNumber(record?.printing),
            width: 142,
        },
        {
            key: 'lastDateCounterOfTheMonth',
            title: 'Date',
            render: (_, record) =>
                record?.lastDateCounterOfTheMonth ? formatDate(record?.lastDateCounterOfTheMonth) : <NoData />,
            width: 60,
        },
    ];

    return (
        <div id="export-wrapper">
            <div className="flex justify-between items-center mb-16">
                <Logo style={{ height: 40 }} />
                <Typography.Title>Analyse détaillée d&rsquo;une machine</Typography.Title>
                <Typography.Text>{formatDate(new Date())}</Typography.Text>
            </div>
            <Descriptions size="small" layout="vertical" colon={false} column={5} className="mb-16 export-descriptions">
                <Descriptions.Item label="Numéro de série">{device?.serial}</Descriptions.Item>
                <Descriptions.Item label="Machine">
                    {device?.hardCodedDeviceInfo?.model ?? <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Nom du client">
                    {device?.hardCodedDeviceInfo?.customer?.name || device?.erpDeviceInfo?.customer?.name || <NoData />}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                    <span className="word-break-all">
                        {device?.hardCodedDeviceInfo?.customer?.mail || device?.erpDeviceInfo?.customer?.mail || (
                            <NoData />
                        )}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label="Adresse">
                    {device?.hardCodedDeviceInfo?.customer?.address?.oneLineAddress || <NoData />}
                </Descriptions.Item>
            </Descriptions>
            <div className="flex gap-24">
                <div style={{ minWidth: 0, flex: '1 1 58%' }}>
                    <ColorUsagePieChart
                        height={200}
                        record={device}
                        theme={{
                            margin: {
                                left: 24,
                                top: 0,
                                right: 24,
                                bottom: 0,
                            },
                        }}
                        showLabelTitle={false}
                        legendPosition="left"
                        small
                    />
                </div>
                <div className="flex flex-col gap-16" style={{ minWidth: 0, flex: '1 1 50%' }}>
                    <CopyPrintUsagePieChart
                        height={200}
                        record={device}
                        showLabelTitle={false}
                        theme={{
                            margin: {
                                left: 24,
                                top: 0,
                                right: 24,
                                bottom: 0,
                            },
                        }}
                        small
                    />
                </div>
            </div>
            <div className="mb-16">
                <AverageConsumptionStatsSummary device={device} />
            </div>
            <div className="mb-16">
                <AverageConsumptionStatsFaxScan record={device} />
            </div>
            <Table<MonthlyCounter>
                rowKey="id"
                className="mb-16"
                columns={columns}
                dataSource={
                    device?.monthlyCounter?.sort(
                        (a, b) =>
                            dayjs(b.lastDateCounterOfTheMonth).valueOf() - dayjs(a.lastDateCounterOfTheMonth).valueOf()
                    ) ?? []
                }
                pagination={false}
                locale={{
                    emptyText: (
                        <Empty
                            className="mb-32 text-taupe"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Aucun résultat"
                        />
                    ),
                }}
                bordered
            />
            <div className="flex gap-24 break-inside-avoid">
                <div style={{ flex: '1 1 50%', height: 200, minWidth: 0 }}>
                    <YearlyVolumeEvolution
                        height={200}
                        device={device}
                        theme={{
                            margin: {
                                left: 42,
                                top: 6,
                                right: 0,
                                bottom: 24,
                            },
                        }}
                        legendInHeader
                    />
                </div>
                <div style={{ flex: '1 1 50%', height: 200, minWidth: 0 }}>
                    <YearlyPrintVolumeEvolution
                        height={200}
                        device={device}
                        theme={{
                            margin: {
                                left: 42,
                                top: 6,
                                right: 0,
                                bottom: 24,
                            },
                        }}
                        legendInHeader
                    />
                </div>
            </div>
        </div>
    );
};

export default ExportDevice;
